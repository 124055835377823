.page-hero {
  background-size: cover;
  background-position-y: 80vh;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 50vh;
}

.section-content {
  padding: 5vw;
}

.page-section.image-left .section-image {
  order: 1;
}

.page-section.image-left .section-content {
  order: 2;
}

.page-section.image-right .section-image {
  order: 2;
}

.page-section.image-right .section-content {
  order: 1;
}

.section-image {
  position: relative;
  max-width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.section-title-overlay {
  font-family: "Questrial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
  font-size: 5em;
  text-align: center;
  font-weight: bold;
  text-shadow: 2px 0px 0 #ed1c24, -2px -0px 0 #93d6db;
  letter-spacing: 0.1em;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  /* Tablet and smaller */

  .page-hero {
    background-position-y: -15vh;
  }

  .page-section {
    grid-template-columns: 1fr; /* Switch to one column */
    height: auto; /* Adjust height to fit content */
  }

  .page-section .section-image,
  .page-section .section-content {
    order: 1 !important; /* Ensure all elements are ordered as 1 */
  }

  .section-image {
    height: 20vh;
  }

  .text-overlay {
    position: relative !important; /* Change position to relative */
  }

  .section-title-overlay {
    font-size: 4em;
  }
}

@media (max-width: 768px) {
  /* Mobile */

  .page-hero {
    background-position-y: 0vh;
  }

  .page-section {
    grid-template-columns: 1fr; /* Switch to one column */
    height: auto; /* Adjust height to fit content */
  }

  .page-section .section-image,
  .page-section .section-content {
    order: 1 !important; /* Ensure all elements are ordered as 1 */
  }

  .section-image {
    height: 20vh;
  }

  .section-title-overlay {
    font-size: 2em;
  }
}
