body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
  margin: 0;
  background-color: #f0f0f0;
}
canvas {
  display: block;
  height: 70vh;
  width: 100%;
  margin-top: -5vh;
}
h1 {
  font-size: 3em;
  color: #000;
  text-align: center;
  margin-bottom: 40px;
}
h2 {
  font-size: 1.8em;
  color: #000;
}
h3 {
  font-size: 1.2em;
  color: #444;
  margin-top: 20px;
}
p {
  margin-top: -0.5em;
  margin-bottom: 2.5em;
  letter-spacing: 0.05em;
}
.service {
  margin-bottom: 60px;
}
.image-placeholder {
  background-color: #d9d9d9;
  background-size: cover;
  background-position: center;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 1.2em;
  color: #666;
  border-radius: 5px;
}

.subtitle {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 60px;
}
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.content-area {
  flex: 1;
  transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
}
.hamburger {
  position: fixed;
  top: 20px;
  right: calc(5vw - 14px);
  z-index: 1000;
  cursor: pointer;
  width: 30px;
  height: 25px;
}
.hamburger div {
  width: 100%;
  height: 5px;
  background-color: #333;
  position: absolute;
  left: 0;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger div:nth-child(1) {
  top: 0;
}
.hamburger div:nth-child(2) {
  top: 10px;
}
.hamburger div:nth-child(3) {
  top: 20px;
}
.hamburger.open div:nth-child(1) {
  transform: rotate(45deg);
  top: 10px;
}
.hamburger.open div:nth-child(2) {
  opacity: 0;
}
.hamburger.open div:nth-child(3) {
  transform: rotate(-45deg);
  top: 10px;
}

.page {
  width: 100%;
  height: 100%;
  transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  padding: 20px;
  box-sizing: border-box;
  left: 100%;
}
.page.active {
  left: 0;
}
.page-container {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.text {
  font-family: "Unbounded", sans-serif;
  font-size: 80px;
  font-weight: 400; /* Bold weight */
  text-transform: uppercase;
  color: #2c2c2c; /* Dark gray color for the top layer */
  text-shadow: 2px 0px 0 #ed1c24, /* Red shadow */ -2px -0px 0 #93d6db; /* Green shadow */
  letter-spacing: 20px; /* Increase letter spacing */
  margin-top: -5vh;
}
.text-sm {
  font-family: "Unbounded", sans-serif;
  font-size: 10px;
  font-weight: 700; /* Bold weight */
  text-transform: uppercase;
  color: #2c2c2c; /* Dark gray color for the top layer */
  letter-spacing: 20px; /* Increase letter spacing */
}

.home-text {
  font-family: "Unbounded", sans-serif;
  font-size: 5em;
  font-weight: 400; /* Bold weight */
  text-transform: uppercase;
  color: #2c2c2c; /* Dark gray color for the top layer */
  text-shadow: 2px 0px 0 #ed1c24, /* Red shadow */ -2px -0px 0 #93d6db; /* Green shadow */
  letter-spacing: 0.5em; /* Increase letter spacing */
  margin-top: -5vh;
}
.home-text-sm {
  font-family: "Unbounded", sans-serif;
  font-size: 0.75em;
  font-weight: 700; /* Bold weight */
  text-transform: uppercase;
  color: #2c2c2c; /* Dark gray color for the top layer */
  letter-spacing: 2em; /* Increase letter spacing */
}

.title-text {
  font-family: "Unbounded", sans-serif;
  color: black;
  font-size: 5em;
  letter-spacing: 0.5em;
  text-align: center;
  text-shadow: 3px 2px 2px cyan, -3px -2px 2px red;
  font-weight: bold;
  animation: title-shadow-move 3s cubic-bezier(0.68, -0.75, 0.27, 1.75) 1.5s
    infinite;
}

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  min-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4); /* Dark semi-transparent overlay */
}

.footer {
  padding: 10px 20px;
  background-color: #f0f0f0; /* Adjust background color as needed */
  position: relative;
  bottom: 0;
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-self: center;
}

.footer-line {
  border-top: 1px solid #ccc; /* Thin line */
  margin-bottom: 10px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.footer-left {
  text-align: left;
}

.footer-right {
  text-align: right;
}

/* Fade-in animation */
.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

/* Fade-out animation */
.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.full-section {
  grid-template-columns: 1fr;
}

@keyframes title-shadow-move {
  0% {
    text-shadow: 3px 2px 2px cyan, -3px -2px 2px red;
  }
  25% {
    text-shadow: 4px -3px 3px cyan, -4px 3px 3px red;
  }
  50% {
    text-shadow: -5px 4px 4px cyan, 5px -4px 4px red;
  }
  75% {
    text-shadow: 3px -2px 2px cyan, -3px 2px 2px red;
  }
  100% {
    text-shadow: 3px 2px 2px cyan, -3px -2px 2px red;
  }
}

/* Responsive Styles */
@media (max-width: 1024px) {

}

@media (max-width: 768px) {
  .title-text {
    font-size: 2em;
  }

  .home-text {
    font-size: 2em;
  }
  .home-text-sm {
    font-size: 0.5em;
  }

}
