/* General Styles */
.contact-page {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

h1, h2, h3 {
  font-weight: bold;
  margin-bottom: 10px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Hero Section */
.hero-section {
  background-size: cover;
  background-position: center;
  padding: 150px 20px;
  color: #fff;
  text-align: center;
  position: relative;
}

.hero-section .overlay {
  background: rgba(0, 0, 0, 0.6);
  padding: 60px 20px;
  border-radius: 10px;
}

.hero-section h1 {
  font-size: 48px;
  margin-bottom: 10px;
}

.hero-section p {
  font-size: 20px;
}

/* Contact Form Section */
.contact-form-section {
  padding: 40px 20px;
  max-width: 800px;
  margin-top: 5vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  border-radius: 10px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.contact-form label {
  flex: 1;
  margin-right: 20px;
  font-weight: bold;
  color: #444;
  text-align: right;
  font-size: 1rem; /* Slightly larger font size */
}

.contact-form input,
.contact-form textarea {
  flex: 3;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

.submit-button {
  padding: 15px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #000000;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.3);
}


/* Contact Information Section */
.contact-info-section {
  display: flex;
  justify-content: space-around;
  padding: 50px 20px;
  background-color: #fff;
  border-radius: 10px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-details {
  max-width: 300px;
}

.contact-details h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.social-links {
  display: flex;
  flex-direction: column;
}

.social-links h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.social-links a {
  margin-bottom: 10px;
  font-size: 16px;
}

.social-links a:hover {
  color: #0056b3;
}

/* Footer Section */
.contact-footer {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
}

@media (max-width: 768px) {
  .contact-form-section {
    margin-top: 0;
  }
}