.menu {
  width: 0;
  background-color: #f1f1f1;
  transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow-x: hidden;
  padding-top: 60px;
  position: fixed;
  right: 2vw;
  top: 0;
}
.menu-inner {
  width: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-item {
  font-size: 24px;
  color: #333;
  margin: 15px 0;
  transition: all 0.3s ease;
  position: relative;
  transform-style: preserve-3d;
}

.menu-item svg {
  width: 24px;
  height: 24px;
  fill: currentColor;
  transition: all 0.3s ease;
}

.menu-item:hover svg {
  transform: scale(1.1);
}

.menu-item:nth-child(3n + 1):hover svg {
  fill: #ff0000;
  filter: drop-shadow(3px 3px 0 #00ffff);
}

.menu-item:nth-child(3n + 2):hover svg {
  fill: #00ff00;
  filter: drop-shadow(-3px -3px 0 #ff00ff);
}

.menu-item:nth-child(3n + 3):hover svg {
  fill: #0000ff;
  filter: drop-shadow(3px -3px 0 #ffff00);
}

/* Section Menu Styles */
.section-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.section-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.section-button:hover {
  transform: scale(1.1);
}

.section-button svg {
  width: 40px;
  height: 40px;
  fill: #333;
}

.section-separator {
  width: 8px;
  height: 8px;
  background-color: #d5d5d5;
  border-radius: 50%;
  margin: 5px 0;
}
